import { Box } from '@mui/material';
import { WithAuth } from 'auth/WithAuth';
import { Footer, FOOTER_HEIGHT } from 'components/luComponents/Footer';
import { Header, HEADER_HEIGHT } from 'components/luComponents/Header';
import { Outlet } from 'react-router-dom';

const CONTENT_HEIGHT = 100 - HEADER_HEIGHT - FOOTER_HEIGHT;

export const LayoutWithAuth: React.FC = () => (
  <WithAuth>
    <Box height="100vh" width="100vw">
      <Header />
      <Box sx={{ height: `${CONTENT_HEIGHT}vh`, overflowX: 'hidden', overflowY: 'auto', maxWidth: '95vw', margin: 'auto' }}>
        <Outlet />
      </Box>
      <Box sx={{ position: 'absolute', bottom: '0px', width: '100%' }}>
        <Footer />
      </Box>
    </Box>
  </WithAuth>
);
