import { Alert, Button, MenuItem, Modal, Paper, Select, Stack, Typography } from '@mui/material';
import { type GetAvailableStandorteByRegionOutput } from 'dtos';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type NewStandortSlotModalProps = {
  readonly availableStandorte: GetAvailableStandorteByRegionOutput;
  readonly open: boolean;
  readonly handleClose: () => void;
  readonly handleNewSlot: (standortId: number) => void;
};

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  padding: 4,
};

export const NewStandortSlotModal: React.FC<NewStandortSlotModalProps> = (props: NewStandortSlotModalProps) => {
  const { availableStandorte, open, handleClose, handleNewSlot } = props;
  const [selectedStandortId, setSelectedStandortId] = useState<number | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const onSaveBtn = (): void => {
    if (selectedStandortId) {
      handleNewSlot(selectedStandortId);
    } else {
      enqueueSnackbar('Bitte wählen Sie einen Standort aus', { variant: 'error' });
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper sx={style}>
        <Stack direction="column" spacing={1}>
          <Typography variant="h6" component="h2">
            Standort auswählen
          </Typography>
          {availableStandorte ? (
            <Select fullWidth value={selectedStandortId} onChange={(evt) => setSelectedStandortId(evt.target.value as number)}>
              {availableStandorte.map((standort) => (
                <MenuItem key={standort.standortSapId} value={standort.standortSapId}>
                  {standort.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Alert severity="error">Keine Standorte verfügbar</Alert>
          )}
          <Stack direction="row" justifyContent="end">
            <Button onClick={handleClose}>Abbrechen</Button>
            <Button onClick={() => onSaveBtn()}>Speichern</Button>
          </Stack>
        </Stack>
      </Paper>
    </Modal>
  );
};
