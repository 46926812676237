import { AbstractCalendarEvent, type BlockingData } from './AbstractCalendarEvent';
import { endOfDay, startOfDay } from 'date-fns';
import { resourcenBlockungTypes } from 'dtos';
import { type TerminInfo } from 'utils/TerminInfo';

export class CalendarExperteEvent extends AbstractCalendarEvent {
  public readonly type = resourcenBlockungTypes.EXPERTE;

  public resource: `t-${string}`;

  public constructor(
    start: Date,
    end: Date, // don't import the real CalendarResource type here - otherwise, there will by cyclic includes
    calendarResource: {
      id: `t-${string}`;
      getDataId: () => number;
      terminInfo: Pick<TerminInfo, 'id' | 'sapid' | 'division' | 'ablauf'>;
    },
    data?: BlockingData,
  ) {
    super(startOfDay(start), endOfDay(end), calendarResource, data);
    this.resource = calendarResource.id;
  }
}
