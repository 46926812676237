import { content } from './TerminPlanungsstatusCard.content';
import { FormControlLabel, Stack, Switch } from '@mui/material';
import { EditButtonGroup } from 'components/general/EditButtonGroup';
import { type GetPlanungsinformationOutput } from 'dtos';
import { useSnackbar } from 'notistack';
import { type ChangeEvent, useState } from 'react';
import { trpc } from 'trpc';

type ManuellerTermincheckProps = {
  readonly planungsinformation: GetPlanungsinformationOutput;
  readonly themaId: number;
};

export const ManuellerTerminCheck: React.FC<ManuellerTermincheckProps> = ({ planungsinformation, themaId }: ManuellerTermincheckProps) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [termincheck, setTermincheck] = useState<boolean>(planungsinformation.manuellerTermincheck);

  const { enqueueSnackbar } = useSnackbar();

  const termincheckMutation = trpc.thema.changeTermincheck.useMutation({
    onSuccess: () => {
      enqueueSnackbar(termincheck ? content.snackBarSuccessManuell : content.snackBarSuccessAutomatisch, { variant: 'success' });
      setIsEditMode(false);
    },
    onError: () => {
      enqueueSnackbar(content.snackBarFail, { variant: 'error' });
      setIsEditMode(false);
    },
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setTermincheck(event.target.checked);
  };

  const cancelEditMode = (): void => {
    if (isEditMode) {
      setIsEditMode(false);
      setTermincheck(planungsinformation.manuellerTermincheck);
    }
  };

  const save = async (): Promise<void> => {
    await termincheckMutation.mutateAsync({ id: themaId, isTermincheckManuell: termincheck });
  };

  return (
    <Stack>
      <EditButtonGroup isEditMode={isEditMode} setEditMode={setIsEditMode} onSave={save} onCancel={cancelEditMode} />
      <FormControlLabel
        disabled={!isEditMode}
        sx={{
          '&MuiSwitch-switchBase.Mui-checked.Mui-disabled': { opacity: 0.4, color: 'black !important' },
          '& .MuiFormControlLabel-label.Mui-disabled': { opacity: 1, color: 'black !important' },
        }}
        control={<Switch checked={termincheck} onChange={handleChange} />}
        label="Manuelle Terminanfrage"
      />
    </Stack>
  );
};
