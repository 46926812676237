import { AbstractCalendarEvent, type BlockingData } from './AbstractCalendarEvent';
import { resourcenBlockungTypes } from 'dtos';
import { type TerminInfo } from 'utils/TerminInfo';

export class CalendarRaumEvent extends AbstractCalendarEvent {
  public readonly type = resourcenBlockungTypes.RAUM;

  public readonly resource: `r-${string}`;

  public constructor(
    start: Date,
    end: Date, // don't import the real CalendarResource type here - otherwise, there will by cyclic includes
    calendarResource: {
      id: `r-${string}`;
      getDataId: () => number;
      terminInfo: Pick<TerminInfo, 'id' | 'sapid' | 'division' | 'ablauf'>;
    },
    data?: BlockingData,
  ) {
    super(start, end, calendarResource, data);
    this.resource = calendarResource.id;
  }
}
