import { useDraggable } from '@dnd-kit/core';
import { type ReactNode } from 'react';

type DraggableProps = {
  readonly id: string;
  readonly children: ReactNode;
};

export const Draggable: React.FC<DraggableProps> = (props: DraggableProps) => {
  const { setNodeRef, listeners, attributes, transform } = useDraggable({ id: props.id });

  return (
    <div ref={setNodeRef} {...listeners} {...attributes} style={{ transform: `translate3d(${transform?.x ?? 0}px, ${transform?.y ?? 0}px, 0)` }}>
      {props.children}
    </div>
  );
};
