import { type MbscCalendarEvent } from '@mobiscroll/react';
import { type ExperteBlockungQuelle, experteBlockungQuelle, type GetExpertenByThemaOutput, type ResourcenBlockungType } from 'dtos';
import { type TerminInfo } from 'utils/TerminInfo';
import { v4 } from 'uuid';

export type BlockingData = {
  blockungsTyp?: GetExpertenByThemaOutput[number]['blockings'][number]['blockungsTyp'];
  quelle: ExperteBlockungQuelle;
  terminId?: string;
  division?: string;
  isBookmarked: boolean;
  ablauf: Array<{ start: Date; end: Date }>;
};

export abstract class AbstractCalendarEvent implements MbscCalendarEvent {
  protected abstract type: ResourcenBlockungType;

  public id: string;

  // is either experteSapId, roomId, or internal database id of standortSlot
  public dataId: number;

  // This is basically a calendarResourceId. Don't rename this - mobiscroll needs this exact name to work
  public abstract resource: string;

  public start: Date;

  public end: Date;

  public isCurrentSelection: boolean;

  public isOldSelection: boolean;

  // don't delete me - I am not used in our code, but I am used by Mobiscroll
  public readonly allDay: boolean = true;

  public data: BlockingData;

  public constructor(
    start: Date,
    end: Date,
    // don't import the real CalendarResource type here - otherwise, there will by cyclic includes
    calendarResource: {
      getDataId: () => number;
      terminInfo: Pick<TerminInfo, 'id' | 'sapid' | 'division' | 'ablauf'>;
    },
    data?: BlockingData,
  ) {
    this.id = v4();
    this.dataId = calendarResource.getDataId();
    this.start = start;
    this.end = end;
    this.isCurrentSelection = false;
    this.isOldSelection = false;

    this.data = {
      blockungsTyp: data?.blockungsTyp,
      quelle: data?.quelle ?? experteBlockungQuelle.VAMOS,
      terminId: data?.terminId ?? String(calendarResource.terminInfo.id),
      division: data?.division ?? calendarResource.terminInfo.division,
      isBookmarked: false,
      ablauf: [{ start, end }],
    };
  }
}
