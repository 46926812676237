import { content } from './TerminZeitlicherAblaufCard.content';
import { type GetPlanungsinformationOutput } from 'dtos';

export const printZusatzinfo = (planungsinformation: GetPlanungsinformationOutput): string => {
  let print = '';

  if (planungsinformation.zusatzinfoAblauf === null) {
    print += content.noInfoAvailable;
  } else {
    print += planungsinformation.zusatzinfoAblauf;
  }

  return print;
};
