import { vivaStatus } from 'dtos';

export const mapVivaStatus = (givenStatus?: string) => {
  switch (givenStatus) {
    case vivaStatus.INPLANUNG:
      return 'In Planung';
    case vivaStatus.ABGESCHLOSSEN:
      return 'Planung abgeschlossen';
    case vivaStatus.FREIGEGEBEN:
      return 'Planung freigegeben';
    default:
      return '-';
  }
};
