import { type TerminProps } from '../utils';
import { ManuellerTerminCheck } from './ManuellerTermincheck';
import { TermineImPlanungsintervall } from './TermineImPlanungsintervall';
import { content } from './TerminPlanungsstatusCard.content';
import { printPlanungszeitraum, printTermineImPlanungsintervall, printWorkflowStatus } from './TerminPlanungsstatusCard.utils';
import { Draggable } from '@mobiscroll/react';
import { Stack } from '@mui/material';
import { AccordionWithHeading } from 'components/general/AccordionWithHeading';
import { ContentLoading } from 'components/general/ContentLoading';
import { Tablelike } from 'components/general/Tablelike';
import { type GetPlanungsinformationOutput } from 'dtos';

type TerminPlanungsstatusCardProps = {
  readonly themaId: number;
} & TerminProps;

export const TerminPlanungsstatusCard: React.FC<TerminPlanungsstatusCardProps> = (props: TerminPlanungsstatusCardProps) => {
  const { planungsinformationQuery, themaId } = props;

  if (planungsinformationQuery.isError) {
    throw new Error(planungsinformationQuery.error.message);
  }

  if (planungsinformationQuery.isLoading || !planungsinformationQuery.data) {
    return (
      <Draggable id={content.draggableId}>
        <AccordionWithHeading heading={content.heading}>
          <ContentLoading />
        </AccordionWithHeading>
      </Draggable>
    );
  }

  const planungsinformation: GetPlanungsinformationOutput = planungsinformationQuery.data;

  return (
    <Draggable id={content.draggableId}>
      <AccordionWithHeading heading={content.heading}>
        <Stack sx={{ paddingTop: 2 }}>
          <Tablelike
            withDivider
            xsLeft={6}
            rows={[
              [content.fields.planungszeitraum, printPlanungszeitraum(planungsinformation)],
              [content.fields.workflowStatus, printWorkflowStatus(planungsinformation)],
            ]}
          />
          <Tablelike withDivider xsLeft={6} rows={[[<TermineImPlanungsintervall key={0} />, printTermineImPlanungsintervall(planungsinformation)]]} />
          <ManuellerTerminCheck planungsinformation={planungsinformation} themaId={themaId} />
        </Stack>
      </AccordionWithHeading>
    </Draggable>
  );
};
