import { CalendarResourceOverview } from './planung/CalendarResourceOverview';
import { Paper } from '@mui/material';
import { ContentLoading } from 'components/general/ContentLoading';
import { set } from 'date-fns';
import { type CreateVeranstaltungInput, type CreateVeranstaltungOutput, geschaeftsbereiche, type ResourcenBlockungen } from 'dtos';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { trpc } from 'trpc';

type VeranstaltungsEditProps = {
  readonly themaId: number;
  readonly themaSapId: number;
  readonly veranstaltungId: number | null;
};

const convertTimeStringToDate = (timeString: string): Date => {
  const [hours, minutes] = timeString.split(':').map(Number);
  return set(new Date(), { hours, minutes, seconds: 0, milliseconds: 0 });
};

export const VeranstaltungsEdit: React.FC<VeranstaltungsEditProps> = (props: VeranstaltungsEditProps) => {
  const { themaId, themaSapId, veranstaltungId } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const navigate = useNavigate();

  let ablaufTageTemplate = null;
  let ablauf = null;
  let status;
  if (veranstaltungId === null) {
    // this is what happens in case of creating a new Veranstaltung
    const query = trpc.thema.getThema.useQuery({ themaId });
    status = query.status;
    ablaufTageTemplate = query.data?.thema.planungsinformation?.ablaufTageTemplate;
  } else {
    // this is what happens if the veranstaltung already exists
    const query = trpc.veranstaltung.getVeranstaltungById.useQuery({ id: veranstaltungId });
    status = query.status;
    ablauf = query.data?.ablauf ?? null;
  }

  const trpcUtils = trpc.useUtils();
  const createVeranstaltungMutation = trpc.veranstaltung.createVeranstaltung.useMutation({
    onSuccess: (createVeranstaltungOutput: CreateVeranstaltungOutput) => {
      if (typeof createVeranstaltungOutput.errorMessage === 'undefined') {
        enqueueSnackbar('Veranstaltung gespeichert!', { variant: 'success' });
      } else {
        enqueueSnackbar(createVeranstaltungOutput.errorMessage, { variant: 'error' });
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  if (status === 'pending') {
    return <ContentLoading />;
  }

  const toggleFullscreen = (): void => {
    setIsFullscreen((prev) => !prev);
  };

  const onSave = async (blockungen: ResourcenBlockungen): Promise<void> => {
    const input: CreateVeranstaltungInput = {
      themaId,
      blockungen,
    };

    await createVeranstaltungMutation.mutateAsync(input);
    await trpcUtils.invalidate();
  };

  const onCancel = (): void => {
    navigate(`/thema/${themaSapId}`);
  };

  return (
    <Paper elevation={0} sx={{ width: '100%', height: '100%' }}>
      <CalendarResourceOverview
        onSave={onSave}
        onCancel={onCancel}
        themaId={themaId}
        isFullscreen={isFullscreen}
        toggleFullscreen={toggleFullscreen}
        initDate={new Date()}
        terminInfo={{
          themaId,
          division: geschaeftsbereiche.KFF,
          // todo wenn ablaufTagTemplate nicht vorhanden dann wollen wir dem User einen Fehler anzeigen: keine Planungsinformation vorhanden
          ablauf: ablauf ?? [
            ...(ablaufTageTemplate?.map((ablaufTagTemplate) => ({
              startDateTime: convertTimeStringToDate(ablaufTagTemplate.startZeit),
              endeDateTime: convertTimeStringToDate(ablaufTagTemplate.endZeit),
            })) ?? []),
          ],
        }}
      />
    </Paper>
  );
};
