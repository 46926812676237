import { content } from './TerminEigenschaftenCard.content';
import { type ThemaFromThemaRouter } from 'dtos';
import { CSERV_AKA_PRODUKT_ID_NICHT_VORHANDEN } from 'utils/constants';
import { formatDate } from 'utils/dateFormat';

export const printAkaProduktId = (thema: ThemaFromThemaRouter): string => {
  let print = '';

  if (thema.akaProduktId === CSERV_AKA_PRODUKT_ID_NICHT_VORHANDEN) {
    print = content.noInfoAvailable;
  } else {
    print = thema.akaProduktId;
  }

  return print;
};

export const printSapProduktId = (thema: ThemaFromThemaRouter): string => {
  const print: string = thema.produktSapId.toString();

  return print;
};

export const printSprache = (thema: ThemaFromThemaRouter): string => thema.sprachen.join(', ');

export const printletzteAenderung = (thema: ThemaFromThemaRouter): string => {
  const print: string = formatDate(thema.updatedAt);

  return print;
};
