import { Add, Remove } from '@mui/icons-material';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Button, Stack, Typography } from '@mui/material';
import { AccordionWithHeading } from 'components/general/AccordionWithHeading';
import { Draggable } from 'components/general/dnd/Draggable';
import { EditButtonGroup } from 'components/general/EditButtonGroup';
import { TimeRangeEdit } from 'components/general/TimeRangeEdit';
import { type AblaufTag, type Veranstaltung, vivaStatus } from 'dtos';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { trpc } from 'trpc';
import { formatTimeRangeForDateWithWeekday } from 'utils/dateFormat';

type TerminAblaufCardProps = {
  readonly veranstaltung: Veranstaltung;
};

const compareRange = (a: AblaufTag, b: AblaufTag): number => a.startDateTime.getTime() - b.startDateTime.getTime();

export const TerminAblaufCard: React.FC<TerminAblaufCardProps> = ({ veranstaltung }: TerminAblaufCardProps) => {
  const [ablauf, setAblauf] = useState<AblaufTag[]>([]);
  const [isEditMode, setIsEditMode] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const veranstaltungUtils = trpc.useUtils().veranstaltung;
  const ablaufMutation = trpc.veranstaltung.mutateAblauf.useMutation({
    onSuccess: () => {
      void veranstaltungUtils.invalidate();
    },
  });

  useEffect(() => {
    setAblauf(veranstaltung.ablauf.sort(compareRange));
  }, [veranstaltung]);

  const addAblauf = (): void =>
    setAblauf((prev) => {
      const newStart = new Date((prev.at(-1)?.startDateTime.getTime() ?? Date.now()) + 24 * 60 * 60 * 1_000);
      const newEnd = new Date((prev.at(-1)?.endeDateTime.getTime() ?? Date.now()) + 24 * 60 * 60 * 1_000);
      return [...prev, { startDateTime: newStart, endeDateTime: newEnd }];
    });

  const removeDay = (): void => {
    setAblauf((prev) => prev.slice(0, -1));
  };

  const onEditCancel = (): void => {
    setAblauf(veranstaltung.ablauf);
  };

  const onSave = async (): Promise<void> => {
    await ablaufMutation.mutateAsync({ veranstaltungId: veranstaltung.id, ablauf });
    enqueueSnackbar({ variant: 'success', message: 'Ablauf geändert' });
  };

  const onTimeChange =
    (range: AblaufTag) =>
    (start: Date | null, end: Date | null): void => {
      range.startDateTime = start ?? range.startDateTime;
      range.endeDateTime = end ?? range.endeDateTime;
    };

  return (
    <Draggable id="ablauf">
      <AccordionWithHeading heading="Zeitlicher Ablauf">
        <Stack>
          {veranstaltung.vivaStatus === vivaStatus.INPLANUNG && <EditButtonGroup isEditMode={isEditMode} setEditMode={setIsEditMode} onSave={onSave} onCancel={onEditCancel} />}
          <Timeline
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.2,
              },
            }}
          >
            {ablauf.map((range, idx) => (
              <TimelineItem key={range.startDateTime.getTime()}>
                <TimelineOppositeContent align="right">{idx + 1}. Tag</TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  {idx + 1 < ablauf.length ? <TimelineConnector /> : null}
                </TimelineSeparator>
                <TimelineContent>
                  {isEditMode ? (
                    <TimeRangeEdit start={range.startDateTime} end={range.endeDateTime} onChange={onTimeChange(range)} autoArrange />
                  ) : (
                    <Typography>{formatTimeRangeForDateWithWeekday(range.startDateTime, range.endeDateTime)}</Typography>
                  )}
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
          {isEditMode && (
            <Stack direction="row" justifyContent="center" spacing={2}>
              <Button startIcon={<Remove />} color="error" onClick={removeDay}>
                Tag entfernen
              </Button>
              <Button onClick={addAblauf} startIcon={<Add />} sx={{ borderRadius: 1 }}>
                {' '}
                Tag hinzufügen{' '}
              </Button>
            </Stack>
          )}
        </Stack>
      </AccordionWithHeading>
    </Draggable>
  );
};
