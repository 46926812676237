import { type BackendConfigJson } from './BackendInfrastructure';
import { AwsRum } from 'aws-rum-web';

export const initializeRum = (config: BackendConfigJson) => {
  const appVersion = '1.0.0.0';
  const appRegion = 'eu-central-1';

  if (!config.RumMonitorId) {
    console.warn('No RUM monitor ID provided, skipping RUM initialization');
    return;
  }

  // eslint-disable-next-line no-new
  new AwsRum(config.RumMonitorId, appVersion, appRegion, {
    sessionSampleRate: 1,
    guestRoleArn: config.RumGuestRoleArn,
    identityPoolId: config.RumIdentityPoolId,
    endpoint: 'https://dataplane.rum.eu-central-1.amazonaws.com',
    telemetries: ['performance', 'errors', 'http'],
    allowCookies: true,
    enableXRay: true,
  });
};
