import './App.css';
import { ConnectToVpnWarning } from './components/general/ConnectVpnWarning';
import { VivaPageLoading } from './components/general/VivaPageLoading';
import lightHouseTheme from './theme/lightHouseTheme';
import { checkIfBackendIsReachable } from './utils/BackendInfrastructure';
import { type PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ScopedCssBaseline, ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { appRouter } from 'appRouter';
import { getIdToken } from 'auth/getToken';
import { ReactQueryDevtoolsForProduction } from 'components/ReactQueryDevtoolsForProduction';
import { SnackbarProvider } from 'notistack';
import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { queryClient, trpc, trpcClient } from 'trpc';

const TrpcProvider = trpc.Provider;

type AppProps = {
  readonly msalInstance: PublicClientApplication;
};

const App: React.FC<AppProps> = ({ msalInstance }: AppProps) => {
  const [backendState, setBackendState] = useState<'loading' | 'reachable' | 'unreachable'>('loading');

  useEffect(() => {
    checkIfBackendIsReachable()
      // eslint-disable-next-line promise/prefer-await-to-then
      .then((isBackendReachable) => {
        setBackendState(isBackendReachable ? 'reachable' : 'unreachable');
      })
      // eslint-disable-next-line promise/prefer-await-to-then
      .catch((error) => {
        console.error(error);
        setBackendState('unreachable');
      });
  }, []);

  const trcClientInstance = trpcClient(getIdToken(msalInstance));

  return (
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={lightHouseTheme}>
        <ScopedCssBaseline>
          <SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'top' }} autoHideDuration={5_000} maxSnack={3}>
            {backendState === 'reachable' && (
              <TrpcProvider client={trcClientInstance} queryClient={queryClient}>
                <QueryClientProvider client={queryClient}>
                  <ReactQueryDevtools initialIsOpen={false} />
                  <ReactQueryDevtoolsForProduction />
                  <RouterProvider router={appRouter} />
                </QueryClientProvider>
              </TrpcProvider>
            )}
            {backendState === 'unreachable' && <ConnectToVpnWarning />}
            {backendState === 'loading' && <VivaPageLoading msg="connecting ..." />}
          </SnackbarProvider>
        </ScopedCssBaseline>
      </ThemeProvider>
    </MsalProvider>
  );
};

export default App;
