import { AccordionWithHeading } from '../../components/general/AccordionWithHeading';
import { EasyBreadcrumbs, type EasyBreadcrumbsItem } from '../../components/general/EasyBreadcrumbs';
import { VeranstaltungListing } from '../../components/pages/thema/VeranstaltungListing';
import { Add } from '@mui/icons-material';
import { Box, Button, Grid2, Stack } from '@mui/material';
import { ContentLoading } from 'components/general/ContentLoading';
import { Droppable } from 'components/general/dnd/Droppable';
import { NoContentInfo } from 'components/general/NoContentInfo';
import { TerminAnsprechpartnerCard } from 'components/pages/termin/TerminAnsprechpartnerCard';
import { TerminEigenschaftenCard } from 'components/pages/termin/TerminEigenschaftenCard';
import { TerminExpertepoolCard } from 'components/pages/termin/TerminExpertepoolCard';
import { TerminGenerellerKommentarCard } from 'components/pages/termin/TerminGenerellerKommentarCard';
import { TerminPlanungsinformationenCard } from 'components/pages/termin/TerminPlanungsinformationenCard';
import { TerminPlanungsstatusCard } from 'components/pages/termin/TerminPlanungsstatusCard';
import { TerminZeitlicherAblaufCard } from 'components/pages/termin/TerminZeitlicherAblaufCard';
import { ThemaHeader } from 'components/ThemaHeader';
import { type GetPlanungsinformationOutput } from 'dtos';
import { useNavigate, useParams } from 'react-router-dom';
import { trpc } from 'trpc';
import { z } from 'zod';

export const ThemaPage: React.FC = () => {
  const navigate = useNavigate();

  const { themaSapId } = useParams();

  const parse = z.coerce.number().safeParse(themaSapId);

  if (!parse.success) {
    throw new Error(`Die Thema SAP ID ${themaSapId} scheint keine Zahl zu sein.`);
  }

  const parsedThemaSapId = parse.data;

  const themaQuery = trpc.thema.getThema.useQuery({ produktSapId: parsedThemaSapId });
  const planungsinformationQuery = trpc.planung.getPlanungsinformation.useQuery<GetPlanungsinformationOutput>({ produktSapId: parsedThemaSapId });

  if (themaQuery.error && themaQuery.error.data?.code !== 'NOT_FOUND') {
    throw new Error('Fehler beim Laden des Themas.', { cause: themaQuery.error });
  }

  if (planungsinformationQuery.error) {
    throw new Error('Fehler beim Laden der Planungsinformationen für das Thema.', { cause: themaQuery.error });
  }

  if (themaQuery.isLoading || planungsinformationQuery.isLoading) {
    return <ContentLoading />;
  }

  const thema = themaQuery.data?.thema;

  if (!thema) {
    return <NoContentInfo text="Kein Thema gefunden" />;
  }

  const breadcrumbs: EasyBreadcrumbsItem[] = [
    {
      href: '/',
      label: 'Dashboard',
    },
    {
      href: '/search/thema',
      label: 'Suche',
    },
    {
      label: 'Thema',
      href: `/thema/${themaSapId}`,
    },
  ];

  return (
    <Stack sx={{ paddingTop: 2 }} spacing={2}>
      <EasyBreadcrumbs items={breadcrumbs} />
      <ThemaHeader thema={thema} />
      <Box>
        <Button onClick={() => navigate(`/thema/${themaSapId}/veranstaltung/neu/planung`)} variant="contained">
          <Add /> Veranstaltung anlegen
        </Button>
      </Box>
      <Grid2 container spacing={2} sx={{ paddingTop: -2 }}>
        <Grid2 size={{ xl: 4, md: 4 }} sx={{ paddingRight: 2, paddingTop: 2 }}>
          <Droppable id="left">
            <Stack spacing={2} sx={{ height: '100%' }}>
              <TerminPlanungsinformationenCard planungsinformationQuery={planungsinformationQuery} />
              <TerminGenerellerKommentarCard planungsinformationQuery={planungsinformationQuery} />
            </Stack>
          </Droppable>
        </Grid2>
        <Grid2 size={{ xl: 4, md: 4 }} sx={{ paddingRight: 2, paddingTop: 2 }}>
          <Droppable id="mid">
            <Stack spacing={2} sx={{ height: '100%' }}>
              <TerminPlanungsstatusCard planungsinformationQuery={planungsinformationQuery} themaId={thema.id} />
            </Stack>
          </Droppable>
        </Grid2>
        <Grid2 size={{ xl: 4, md: 4 }} sx={{ paddingTop: 2 }}>
          <Droppable id="right">
            <Stack spacing={2} sx={{ height: '100%' }}>
              <TerminAnsprechpartnerCard planungsinformationQuery={planungsinformationQuery} />
              <TerminZeitlicherAblaufCard planungsinformationQuery={planungsinformationQuery} />
              <TerminEigenschaftenCard thema={thema} />
            </Stack>
          </Droppable>
        </Grid2>
      </Grid2>
      <Grid2>
        <TerminExpertepoolCard planungsinformationQuery={planungsinformationQuery} />
      </Grid2>
      <AccordionWithHeading heading="Veranstaltungsübersicht">
        <VeranstaltungListing openEdit={(vaSapId) => navigate(`/thema/${themaSapId}/veranstaltung/${vaSapId}`)} themaId={thema.produktSapId} />
      </AccordionWithHeading>
    </Stack>
  );
};
