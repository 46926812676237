import { Chip } from '@mui/material';
import { type VivaStatus } from 'dtos';
import { mapVivaStatus } from 'utils/vivaStatusMapping';

type VaStatusChipProps = {
  readonly vivaStatus: VivaStatus | null;
};

export const VaStatusChip: React.FC<VaStatusChipProps> = ({ vivaStatus }: VaStatusChipProps) => {
  const color = 'primary';

  const status = `${mapVivaStatus(vivaStatus ?? undefined)}`;
  return <Chip color={color} label={status} />;
};
