import { CalendarResourceOverview } from '../thema/planung/CalendarResourceOverview';
import { Button, Stack } from '@mui/material';
import { CalendarIcon } from '@mui/x-date-pickers';
import { type ResourcenBlockungen } from 'dtos';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { trpc } from 'trpc';
import { type TerminInfo } from 'utils/TerminInfo';

type ChangeResourcesProps = {
  readonly terminInfo: TerminInfo & { id: number };
};

export const ChangeResources: React.FC<ChangeResourcesProps> = (props: ChangeResourcesProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const trpcUtils = trpc.useUtils();
  const resourceMutation = trpc.veranstaltung.updateResourcenBlockungenOfVeranstaltung.useMutation({
    onSuccess: () => {
      void trpcUtils.veranstaltung.invalidate();
      void trpcUtils.thema.invalidate();
      void trpcUtils.planung.invalidate();
    },
    onError: () => {
      enqueueSnackbar({ variant: 'error', message: 'Updaten der Resourcen fehlgeschlagen.' });
    },
  });

  const onSave = async (resourcenBlockungen: ResourcenBlockungen): Promise<void> => {
    setIsOpen(false);
    await resourceMutation.mutateAsync({
      veranstaltungId: props.terminInfo.id,
      blockungen: resourcenBlockungen,
    });
    enqueueSnackbar({ variant: 'success', message: 'Updated Raumblockungen' });
  };

  return (
    <Stack>
      <Button onClick={() => setIsOpen(true)} size="small" startIcon={<CalendarIcon />} sx={{ borderRadius: 1 }}>
        {' '}
        Ressourcen ändern{' '}
      </Button>
      {isOpen ? (
        <CalendarResourceOverview
          themaId={props.terminInfo.themaId}
          onSave={onSave}
          onCancel={() => setIsOpen(false)}
          isFullscreen
          toggleFullscreen={() => setIsOpen(false)}
          terminInfo={props.terminInfo}
          initDate={props.terminInfo.ablauf.at(0)?.startDateTime}
        />
      ) : null}
    </Stack>
  );
};
