import { content as terminContent } from '../content';
import { type CservVcTools, type Keyify } from 'dtos';

const tools = {
  MS_TEAMS: 'MS Teams',
  LUX: 'LUX',
  TECHCAST: 'Techcast',
  TRICAT: 'TriCAT',
  ZOOM: 'Zoom',
} as const satisfies Record<Keyify<CservVcTools>, string>;

export const content = {
  ...terminContent,
  draggableId: 'planungsinformationen',
  heading: 'Planungsinformationen (aus ContentServ)',
  fields: {
    dauer: 'Dauer:',
    termine: 'Termine',
    raumgroesse: 'Benötigte Raumgröße:',
    teilnehmer: 'Erwartete Teilnehmer:',
    bestuhlungsart: 'Bestuhlungsart:',
    gruppenraum: 'Gruppenraum:',
    tool: 'Tool:',
    musskopplung: 'Musskopplung:',
    terminreihe: 'Terminreihe:',
    verknuepfung: 'Verknüpfung (B)LOP:',
  },
  tools,
} as const;
