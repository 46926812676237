import { loginRequest } from './config';
import { type PublicClientApplication } from '@azure/msal-browser';

export const getIdToken = (instance: PublicClientApplication) => async (): Promise<string> => {
  const account = instance.getActiveAccount();

  if (!instance || !account) {
    return '';
  } else {
    try {
      const authResult = await instance.acquireTokenSilent({
        ...loginRequest,
        account,
      });
      return authResult.idToken;
    } catch {
      await instance.loginRedirect(loginRequest);
      const authResult = await instance.acquireTokenSilent({
        ...loginRequest,
        account,
      });
      return authResult.idToken;
    }
  }
};
