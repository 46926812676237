import { type AkaVeranstaltungId } from '../../../../../common/dist/model/AkaVeranstaltungId';
import { type GridFilterModelExtended, gridFilterModelExtendedSchema } from '../../../utils/MuiSchemas';
import { AlreadyCorrectMessageSeveral, failBeMessage, FailCheckMessageSeveral, failDbMessage, SuccessMessageSeveral } from '../statusChangeMessages.utils';
import { Cancel, CheckCircle, Info, Warning } from '@mui/icons-material';
import { Button, Grid2, Stack, Typography } from '@mui/material';
import { DataGrid, type GridColDef, type GridFilterModel, type GridRenderCellParams, type GridRowSelectionModel, type GridSortModel } from '@mui/x-data-grid';
import { TrpcLoadingInfo } from 'components/general/TrpcLoadingInfo';
import { VaStatusChip } from 'components/general/VaStatusChip';
import { type ChangeStatusForVeranstaltungenOutput, type GetVeranstaltungByIdOutput, veranstaltungChangeFailedCause, vivaStatus } from 'dtos';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { trpc } from 'trpc';
import { experteName } from 'utils/experteUtils';
import { z } from 'zod';

const StatusCell: React.FC<GridRenderCellParams<GetVeranstaltungByIdOutput>> = (params: GridRenderCellParams<GetVeranstaltungByIdOutput>) => (
  <VaStatusChip vivaStatus={params.row.vivaStatus} />
);

const TeilnehmerCell: React.FC = () => (
  <Stack>
    <Typography variant="body2">- gebucht</Typography>
    <Typography variant="body2">- verfügbar</Typography>
    <Typography variant="body2">- Warteliste</Typography>
  </Stack>
);

const FehlerCell: React.FC<GridRenderCellParams<GetVeranstaltungByIdOutput>> = (params: GridRenderCellParams<GetVeranstaltungByIdOutput>) => {
  switch (params.row.health) {
    case 'warning':
      return <Warning color="warning" />;
    case 'error':
      return <Cancel color="error" />;
    case 'info':
      return <Info color="info" />;
    default:
      return <CheckCircle color="success" />;
  }
};

const getSlotInformation = (rowData: GetVeranstaltungByIdOutput): string => (rowData.slotBezeichnung ? ` (${rowData.slotBezeichnung})` : '');

const veranstaltungsColumns: Array<GridColDef<GetVeranstaltungByIdOutput>> = [
  { field: 'id', headerName: 'AKA-Veranstaltung-ID', flex: 0.7, valueGetter: (_value, row): AkaVeranstaltungId => row.akaVeranstaltungId },
  { field: 'sapid', headerName: 'SAP-ID', flex: 0.7 },
  { field: 'start', headerName: 'Startdatum', flex: 0.5, type: 'date' },
  { field: 'end', headerName: 'Enddatum', flex: 0.5, type: 'date' },
  { field: 'status', headerName: 'Status', flex: 1, renderCell: StatusCell },
  { field: 'regionName', headerName: 'Region', flex: 1 },
  { field: 'ortKuerzel', headerName: 'Ortkürzel', renderCell: (params) => `${params.row.ortKuerzel ?? ''}${getSlotInformation(params.row)}`, flex: 1 },
  {
    field: 'experten',
    headerName: 'Trainer',
    renderCell: (params) => params.row.experteBlockungen.map((experteBlockung) => experteName(experteBlockung.experte)).join(', ') ?? 'Unbekannt',
    flex: 1,
  },
  {
    field: 'teilnehmer',
    headerName: 'Teilnehmer',
    renderCell: TeilnehmerCell,
    flex: 0.5,
  },
  {
    field: 'fehler',
    headerName: 'Fehlerstatus',
    renderCell: FehlerCell,
    flex: 0.5,
  },
];

const persistDataGridModel = (type: 'filter' | 'sort', model: unknown): void => {
  localStorage.setItem(`/thema:${type}`, JSON.stringify(model));
};

const loadFilterModel = (): GridFilterModelExtended | undefined => {
  const filterModel = localStorage.getItem('/thema:filter');
  return filterModel ? gridFilterModelExtendedSchema.parse(JSON.parse(filterModel)) : undefined;
};

type VeranstaltungListingProps = { readonly themaId: number; readonly openEdit: (vaId: number) => void };

export const VeranstaltungListing: React.FC<VeranstaltungListingProps> = ({ openEdit, themaId }: VeranstaltungListingProps) => {
  const vaQuery = trpc.veranstaltung.findByThemaId.useQuery({ themaId });
  const { enqueueSnackbar } = useSnackbar();
  const { data, refetch } = vaQuery;
  const changeStatusMutation = trpc.planung.changeStatusForVeranstaltungen.useMutation({
    onError: () => enqueueSnackbar(failBeMessage, { variant: 'error' }),
    onSuccess: (mutateResult: ChangeStatusForVeranstaltungenOutput) => {
      if (mutateResult.success || mutateResult.cause === veranstaltungChangeFailedCause.HEALTH_CHECK) {
        if (mutateResult.successfullyChangedVeranstaltungen.length > 0) {
          enqueueSnackbar(<SuccessMessageSeveral veranstaltungen={mutateResult.successfullyChangedVeranstaltungen} />, { variant: 'success' });
        }

        if (mutateResult.alreadyInCorrectStatusVeranstaltungen.length > 0) {
          enqueueSnackbar(<AlreadyCorrectMessageSeveral veranstaltungen={mutateResult.alreadyInCorrectStatusVeranstaltungen} />, { variant: 'info' });
        }

        if (mutateResult.changeFailedVeranstaltungen.length > 0) {
          enqueueSnackbar(<FailCheckMessageSeveral veranstaltungen={mutateResult.changeFailedVeranstaltungen} />, { variant: 'error' });
        }

        void refetch();
      } else if (mutateResult.cause === veranstaltungChangeFailedCause.DATABASE) {
        enqueueSnackbar(failDbMessage, { variant: 'error' });
      }
    },
  });

  const [gridSelection, setGridSelection] = useState<GridRowSelectionModel>();
  const [filterModel, setFilterModel] = useState<GridFilterModelExtended | undefined>(loadFilterModel());
  const [sortModel, setSortModel] = useState<GridSortModel>([{ sort: 'desc', field: 'start' }]);
  const handleChangeStatusForSelectedVAs = async (newStatus: typeof vivaStatus.ABGESCHLOSSEN | typeof vivaStatus.FREIGEGEBEN): Promise<void> => {
    if (!gridSelection || gridSelection.length === 0) {
      enqueueSnackbar('Keine Veranstaltungen ausgewählt', { variant: 'warning' });
      return;
    }

    await changeStatusMutation.mutateAsync({
      newStatus,
      idsToChange: z.array(z.coerce.number()).parse(gridSelection),
    });
  };

  return (
    <Grid2 container>
      <Grid2 size={{ xs: 12 }}>
        <Grid2 container justifyContent="end" sx={{ paddingBottom: 1 }}>
          {gridSelection && gridSelection.length > 0 && (
            <Grid2 size={{ xs: 3 }}>
              <Button disabled={changeStatusMutation.isPending} variant="outlined" onClick={async () => await handleChangeStatusForSelectedVAs(vivaStatus.ABGESCHLOSSEN)}>
                auf "Planung abgeschlossen" setzen
              </Button>
              <Button disabled={changeStatusMutation.isPending} variant="outlined" onClick={async () => await handleChangeStatusForSelectedVAs(vivaStatus.FREIGEGEBEN)}>
                auf "Planung freigegeben" setzen
              </Button>
            </Grid2>
          )}
        </Grid2>
        <Stack spacing={1}>
          <TrpcLoadingInfo trpcQuery={vaQuery} entity="Veranstaltungen">
            {data && (
              <DataGrid
                columns={veranstaltungsColumns}
                rows={data}
                onRowClick={(row) => openEdit((row.row as GetVeranstaltungByIdOutput).id)}
                rowHeight={80}
                checkboxSelection
                pageSizeOptions={[5, 10, 50, 100]}
                initialState={{
                  pagination: { paginationModel: { pageSize: 5 } },
                }}
                filterModel={filterModel as unknown as GridFilterModel}
                onFilterModelChange={(newModel) => {
                  setFilterModel(newModel);
                  persistDataGridModel('filter', newModel);
                }}
                sortModel={sortModel}
                onSortModelChange={(newModel) => {
                  setSortModel(newModel);
                  persistDataGridModel('sort', newModel);
                }}
                onRowSelectionModelChange={(newSelection) => setGridSelection(newSelection)}
                rowSelectionModel={gridSelection}
              />
            )}
          </TrpcLoadingInfo>
        </Stack>
      </Grid2>
    </Grid2>
  );
};
