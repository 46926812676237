import { type AccountInfo } from '@azure/msal-browser';
import { useAccount } from '@azure/msal-react';
import { Apps, Logout } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
  Select,
  type SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { vivaFrontendScopes, vivaFrontendScopesArraySchema, type VIVAFrontendScopesType } from 'dtos';
import { type PropsWithChildren, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

type AdminScopeSelectProps = {
  readonly selectedScopes: VIVAFrontendScopesType[];
  readonly setSelectedScopes: React.Dispatch<React.SetStateAction<VIVAFrontendScopesType[]>>;
  readonly account: AccountInfo | null;
};

const AdminScopeSelect: React.FC<AdminScopeSelectProps> = ({ selectedScopes, setSelectedScopes, account }: AdminScopeSelectProps) => {
  const permissionScopeToTextMap: { [key: string]: string } = {
    'planung:edit': 'Planung bearbeiten',
    'thema:edit': 'Thema bearbeiten',
    'system:admin': 'System administrieren',
  };

  const handleScopeChange = (event: SelectChangeEvent<VIVAFrontendScopesType[]>): void => {
    setSelectedScopes(event.target.value as VIVAFrontendScopesType[]);
    sessionStorage.setItem('Viva-Admin-Scopes', JSON.stringify(event.target.value));
  };

  if (account?.idTokenClaims?.roles?.includes('Admin')) {
    return (
      <FormControl size="small" sx={{ width: '130px', pb: 2 }} variant="standard">
        <InputLabel id="admin-role-scope-select-label">Admin-Rechte</InputLabel>
        <Select
          id="admin-role-scope-select"
          labelId="admin-role-scope-select-label"
          value={selectedScopes}
          onChange={handleScopeChange}
          renderValue={(selected) => `${selected.length} ausgewählt`}
          multiple
        >
          {Object.values(vivaFrontendScopes).map((scope) => (
            <MenuItem key={scope} value={scope} sx={{ height: '30px', lineHeight: '30px' }}>
              <Checkbox checked={selectedScopes.includes(scope)} />
              <ListItemText>{permissionScopeToTextMap[scope]}</ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return null;
};

type HeaderProps = PropsWithChildren<{
  readonly userImageURL?: string;
}>;

export const HEADER_HEIGHT = 6 as const;

const getAdminScopesInitially = (): VIVAFrontendScopesType[] => {
  const scopes = sessionStorage.getItem('Viva-Admin-Scopes');
  if (scopes === null) {
    return Object.values(vivaFrontendScopes);
  }

  const safeParsedScopes = vivaFrontendScopesArraySchema.safeParse(JSON.parse(scopes));
  if (safeParsedScopes.success) {
    return safeParsedScopes.data;
  } else {
    return Object.values(vivaFrontendScopes);
  }
};

export const Header: React.FC<HeaderProps> = ({ children, userImageURL }: HeaderProps) => {
  const account = useAccount();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [selectedScopes, setSelectedScopes] = useState<VIVAFrontendScopesType[]>(getAdminScopesInitially());

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  // set admin scopes to session storage if correct role
  useEffect(() => {
    if (account?.idTokenClaims?.roles?.includes('Admin')) {
      sessionStorage.setItem('Viva-Admin-Scopes', JSON.stringify(selectedScopes));
    } else {
      sessionStorage.removeItem('Viva-Admin-Scopes');
    }
  }, [selectedScopes, account?.idTokenClaims?.roles]);

  return (
    <Box>
      <AppBar color="inherit" position="static" sx={{ height: `${HEADER_HEIGHT}vh` }}>
        <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ mr: 2 }}>
          <Stack alignItems="center" direction="row" spacing={2}>
            <a href="/" style={{ textDecoration: 'none' }}>
              <img alt="Haufe Akademie Logo" src="/assets/images/haufe/Haufe_Akademie_Logo_negativ.svg" style={{ maxHeight: `${HEADER_HEIGHT}vh`, backgroundColor: '#075BFA' }} />
            </a>
            <Button onClick={handleClick} variant="text">
              <Apps fontSize="large" />
            </Button>
            <Popper id="apps-popper" open={open} anchorEl={anchorEl}>
              <Paper sx={{ padding: 2 }}>
                <MenuItem>
                  <Link onClick={() => setAnchorEl(null)} to="/reporting/appointmentCheck">
                    <Typography variant="body1">Termincheck</Typography>
                  </Link>
                </MenuItem>
              </Paper>
            </Popper>
            <a href="/" style={{ textDecoration: 'none' }}>
              <Typography color="primary" component="div" sx={{ flexGrow: 1 }} variant="h5">
                Viva
              </Typography>
            </a>
            {children}
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <AdminScopeSelect selectedScopes={selectedScopes} setSelectedScopes={setSelectedScopes} account={account} />
            <Typography variant="h6" margin="auto">
              {account ? account.name?.replace(',', '').split(' ').reverse().join(' ') : account}
            </Typography>
            {userImageURL && <img alt="user" src={userImageURL} />}
            {account && (
              <IconButton sx={{ height: 'fit-content', margin: 'auto' }} href="/auth/logout">
                <Logout />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </AppBar>
    </Box>
  );
};
