import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { getBackendConfig } from '../../../../../utils/BackendInfrastructure';
import {
  type CollapseInfo,
  createCalendarColors,
  createCalendarResources,
  type FilterParameters,
  getResourcenBlockungen,
  groupCalendarResources,
} from './CalendarResourceOverview.utils';
import { type CalendarEvent, CalendarEventHelper, type CalendarResource, CalendarSlotResource, newSlot } from './helperClasses';
import { DisplayCalendarEvent, DisplayCalendarResource, FilterMenuBar, FullScreen, LegendChips, NewStandortSlotModal } from './helperComponents';
import { Eventcalendar, localeDe, type MbscCellClickEvent, type MbscEventClickEvent, setOptions } from '@mobiscroll/react';
import { Cancel, Fullscreen, Save } from '@mui/icons-material';
import { Button, Grid2, Stack, Tooltip, Typography } from '@mui/material';
import { addDays, endOfMonth, startOfMonth, subDays } from 'date-fns';
import { type GetPlanungsinformationOutput, type ResourcenBlockungen, type ResourcenBlockungType, resourcenBlockungTypes } from 'dtos';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { trpc } from 'trpc';
import { type TerminInfo } from 'utils/TerminInfo';

setOptions({
  locale: localeDe,
  theme: 'material',
  themeVariant: 'light',
});

type CalendarResourceOverviewProps = {
  readonly isFullscreen: boolean;
  readonly toggleFullscreen: () => void;
  readonly onSave: (blockungen: ResourcenBlockungen) => Promise<void>;
  readonly onCancel: () => void | Promise<void>;
  readonly terminInfo: TerminInfo;
  readonly initDate?: Date;
  readonly themaId: number;
};

export const CalendarResourceOverview: React.FC<CalendarResourceOverviewProps> = ({
  onSave,
  onCancel,
  initDate,
  terminInfo,
  isFullscreen,
  toggleFullscreen,
  themaId,
}: CalendarResourceOverviewProps) => {
  const [filterParams, setFilterParams] = useState<FilterParameters>({
    region: [],
    standort: [],
    orte: [],
  });
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false);

  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [resources, setResources] = useState<CalendarResource[]>([]);
  const [collapseInfos, setCollapseInfos] = useState<CollapseInfo>({ experte: false, raum: false, slot: true });

  const [timeRange, setTimeRange] = useState<{ start: Date; end: Date }>({
    start: subDays(startOfMonth(initDate ?? new Date()), 2),
    end: addDays(endOfMonth(initDate ?? new Date()), 2),
  });

  const [hiveUrl, setHiveUrl] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const planungsinformationQuery = trpc.planung.getPlanungsinformation.useQuery<GetPlanungsinformationOutput>({ themaId });

  const availableStandorte = trpc.planung.resources.getAvailableStandorteByRegion.useQuery(
    {
      regionSapIds: filterParams.region,
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const [newStandortSlotModalOpen, setNewStandortSlotModalOpen] = useState<boolean>(false);
  const standortSlots = trpc.planung.resources.getStandortSlots.useQuery(
    {
      ...timeRange,
      standortSapIds: filterParams.standort,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const expertePool = trpc.planung.resources.getExpertenByThema.useQuery(
    {
      themaId: terminInfo.themaId,
      ...timeRange,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const orteWithRooms = trpc.planung.resources.getOrteWithRaeume.useQuery(
    {
      regionIds: filterParams.region,
      standortIds: filterParams.standort,
      orteIds: filterParams.orte,
      ...timeRange,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const calendarEventHelper = useMemo(() => new CalendarEventHelper(terminInfo.ablauf), [terminInfo]);

  useEffect(() => {
    const setUrl = async (): Promise<void> => {
      const config = await getBackendConfig();
      if (config.success) {
        setHiveUrl(config.config.HiveAppUrl ?? '');
      }
    };

    void setUrl();
  }, []);

  useEffect(() => {
    if (expertePool.data && orteWithRooms.data && standortSlots.data) {
      const newResources = createCalendarResources(expertePool.data, orteWithRooms.data, standortSlots.data, terminInfo, timeRange.start);
      setResources(newResources);
      const initEvents = calendarEventHelper.initEvents(newResources);

      setEvents(initEvents);
    }
  }, [expertePool.data, orteWithRooms.data, standortSlots.data, terminInfo, calendarEventHelper, timeRange.start]);

  useEffect(() => {
    if (calendarEventHelper) {
      const resourcenBlockungen = getResourcenBlockungen(events);

      const raumBlockungExists = resourcenBlockungen.some((resource) => resource.type === resourcenBlockungTypes.RAUM);
      const standortBlockungExists = resourcenBlockungen.some((resource) => resource.type === resourcenBlockungTypes.SLOT);

      if (!raumBlockungExists && !standortBlockungExists) {
        setIsSaveButtonDisabled(true);
      } else {
        setIsSaveButtonDisabled(false);
      }
    }
  }, [calendarEventHelper, events, filterParams]);

  const handleNewStandortSlot = (standortSapId: number): void => {
    const standortById = availableStandorte.data?.find((standort) => standort.standortSapId === standortSapId);
    if (!standortById) {
      enqueueSnackbar('Standort nicht gefunden welcher für Slot ausgewählt wurde', { variant: 'error' });
      return;
    }

    const slotResource = new CalendarSlotResource(terminInfo, {
      standortId: standortSapId,
      standortName: standortById.name ?? 'unbekannt',
    });

    setResources((prev) => [slotResource, ...prev]);

    setNewStandortSlotModalOpen(false);
  };

  const onCalendarCellClicked = (args: MbscCellClickEvent): void => {
    const resourceId = String(args.resource);
    if (Object.values(resourcenBlockungTypes).includes(resourceId as ResourcenBlockungType)) {
      return;
    }

    const resource = resources.find((res) => res.id === resourceId);
    if (!resource) {
      enqueueSnackbar({ variant: 'error', message: 'Ressource nicht gefunden.' });
      return;
    }

    if (resource.id === newSlot.id) {
      setNewStandortSlotModalOpen(true);
      return;
    }

    setEvents((prev) => {
      const res = calendarEventHelper.addEvent(resource, events, args.date);
      if (res instanceof Error) {
        enqueueSnackbar({ variant: 'error', message: res.message });
        return prev;
      }

      return res;
    });
  };

  const onCalendarEventClicked = (mbscEvent: MbscEventClickEvent): void => {
    const eventId = String(mbscEvent.event.id);
    setEvents(calendarEventHelper.removeEvent(eventId, events));
  };

  const handleSave = async (): Promise<void> => {
    const resourcenBlockungen = getResourcenBlockungen(events);

    setEvents([]);
    await onSave(resourcenBlockungen);
  };

  const handleCancel = async (): Promise<void> => {
    setEvents([]);
    await onCancel();
  };

  if (planungsinformationQuery.error || expertePool.error || availableStandorte.error || standortSlots.error || orteWithRooms.error) {
    return <p>Wir haben technische Probleme :(</p>;
  }

  if (planungsinformationQuery.isLoading || !planungsinformationQuery.data || availableStandorte.isLoading || !availableStandorte.data) {
    return <p>Lädt ...</p>;
  }

  const handleExpansionChange = (resourceType: ResourcenBlockungType, collapsed: boolean): void => {
    setCollapseInfos((prev) => {
      switch (resourceType) {
        case resourcenBlockungTypes.EXPERTE:
          return { ...prev, experte: collapsed };
        case resourcenBlockungTypes.RAUM:
          return { ...prev, raum: collapsed };
        case resourcenBlockungTypes.SLOT:
          return { ...prev, slot: collapsed };
        default:
          return prev;
      }
    });
  };

  const displaySaveTooltip = (): string | undefined =>
    isSaveButtonDisabled ? 'Sie müssen einen Raum oder einen Standort auswählen, um die Veranstaltung speichern zu können!' : undefined;

  return (
    <FullScreen isFullscreen={isFullscreen} withoutCloseButton>
      <NewStandortSlotModal
        open={newStandortSlotModalOpen}
        handleClose={() => setNewStandortSlotModalOpen(false)}
        availableStandorte={availableStandorte.data}
        handleNewSlot={handleNewStandortSlot}
      />
      <Grid2 container padding={2} spacing={2}>
        <Grid2 size={{ xs: 6 }}>
          <Typography variant="h6">Ressourcenübersicht</Typography>
        </Grid2>
        <Grid2 size={{ xs: 6 }} display="flex" justifyContent="right" gap={5}>
          <Button onClick={handleCancel} size="small" startIcon={<Cancel />} color="error" sx={{ borderRadius: 1 }} variant="outlined">
            Abbrechen
          </Button>
          <Button size="small" startIcon={<Fullscreen />} sx={{ borderRadius: 1 }} variant="outlined" onClick={toggleFullscreen}>
            {isFullscreen ? 'Ansicht min' : 'Ansicht max'}
          </Button>
          <Tooltip arrow placement="top" title={displaySaveTooltip()}>
            <div>
              <Button
                startIcon={<Save />}
                size="small"
                onClick={handleSave}
                color="success"
                variant="contained"
                disabled={isSaveButtonDisabled}
                sx={{ borderRadius: 1, boxShadow: 1 }}
              >
                Speichern
              </Button>
            </div>
          </Tooltip>
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          <FilterMenuBar
            interval={timeRange}
            onFilterChange={async (filter) => {
              setFilterParams(filter);
            }}
          />
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <LegendChips />
          </Stack>
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          <Eventcalendar
            colors={createCalendarColors(resources, events)}
            data={events}
            resources={groupCalendarResources(resources, collapseInfos, planungsinformationQuery.data)}
            defaultSelectedDate={initDate ?? new Date()}
            locale={localeDe}
            onCellClick={onCalendarCellClicked}
            onEventClick={onCalendarEventClicked}
            onPageChange={({ firstDay, lastDay }) => setTimeRange({ start: firstDay, end: lastDay })}
            onResourceExpand={(resourceInfo) => handleExpansionChange(resourceInfo.resource as ResourcenBlockungType, false)}
            onResourceCollapse={(resourceInfo) => handleExpansionChange(resourceInfo.resource as ResourcenBlockungType, true)}
            showEventTooltip={false}
            renderResource={(resource: CalendarResource) => <DisplayCalendarResource resource={resource} startTime={timeRange.start} hiveUrl={hiveUrl} />}
            event
            renderScheduleEvent={(event) => <DisplayCalendarEvent event={event.original as CalendarEvent} />}
            theme="material"
            themeVariant="light"
            height={isFullscreen ? '80vh' : '70vh'}
            view={{
              timeline: {
                type: 'month',
                weekNumbers: true,
                startDay: 1,
                endDay: 5,
              },
            }}
          />
        </Grid2>
      </Grid2>
    </FullScreen>
  );
};
