import { type CalendarExperteResource } from './CalendarExperteResource';
import { type CalendarRaumResource } from './CalendarRaumResource';
import { type CalendarSlotResource } from './CalendarSlotResource';
import { resourcenBlockungTypes } from 'dtos';

export type CalendarResource = CalendarExperteResource | CalendarRaumResource | CalendarSlotResource;

export const isExperteResource = (resourceData: CalendarResource): resourceData is CalendarExperteResource => resourceData.type === resourcenBlockungTypes.EXPERTE;
export const isRaumResource = (resourceData: CalendarResource): resourceData is CalendarRaumResource => resourceData.type === resourcenBlockungTypes.RAUM;
export const isSlotResource = (resourceData: CalendarResource): resourceData is CalendarSlotResource => resourceData.type === resourcenBlockungTypes.SLOT;
