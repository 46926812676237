import { ContentLoading } from './general/ContentLoading';
import { content } from './ThemaHeader.content';
import { Grid2, Paper, Stack, Typography } from '@mui/material';
import { type ThemaFromThemaRouter } from 'dtos';
import { Link } from 'react-router-dom';
import { CSERV_AKA_PRODUKT_ID_NICHT_VORHANDEN } from 'utils/constants';
import { printCservFormat } from 'utils/printCservFormat';

type ThemaHeaderProps = {
  readonly thema: ThemaFromThemaRouter | undefined;
};

export const ThemaHeader: React.FC<ThemaHeaderProps> = (props: ThemaHeaderProps) => {
  const { thema } = props;

  if (typeof thema === 'undefined') {
    return <ContentLoading />;
  }

  const showVerknuepfungBLOPundPraesenzseminar = thema.akaProduktId !== CSERV_AKA_PRODUKT_ID_NICHT_VORHANDEN && thema.verknuepfungBlopUndPraesenzseminar;
  return (
    <Paper sx={{ padding: 2 }}>
      <Grid2 container>
        <Grid2 size={{ xs: 8 }}>
          <Stack>
            <Typography color="primary" variant="body2">
              Veranstaltungs<b>THEMA</b>
            </Typography>
            <Typography variant="h4">{thema.titel}</Typography>
            <Typography>
              {thema.themenblock} | {thema.themenbereich}
            </Typography>
          </Stack>
        </Grid2>
        <Grid2 display="flex" justifyContent="end" size={{ xs: 4 }}>
          <Typography>
            {content.format} {printCservFormat(thema)}
            <br />
            {content.buchungsnummer} {thema.buchungsnummer}
            <br />
            {showVerknuepfungBLOPundPraesenzseminar && <Link to={`/thema/${thema.produktSapId}`}>{content.verknuepfung + ' ' + thema.verknuepfungBlopUndPraesenzseminar}</Link>}
          </Typography>
        </Grid2>
      </Grid2>
    </Paper>
  );
};
